import client, { requestConfig } from "./client";

const endpoint = "/api/financial-reports";

const populateData = {
  "populate[Events][populate][Package]": "*",
  "populate[Bookings][populate][Event][fields][0]": "id",
  "populate[Bookings][populate][Event][fields][1]": "NID",
  "populate[Bookings][populate][Event][fields][2]": "StartAt",
  "populate[Bookings][populate][Event][fields][3]": "Name",
  "populate[Winery]": "*",
};

const get = (wineryId, data) =>
  client.get(
    endpoint,
    {
      ...populateData,
      "filters[Winery][id]": wineryId,
      "filters[Status]": data?.status,
      "sort[createdAt]": "desc",
      "pagination[pageSize]":60
    },
    requestConfig()
  );

const update = (data, reportId) =>
  client.put(endpoint + "/" + reportId, data, {
    ...requestConfig(),
    params: populateData
  });

const getById = (reportId) =>
  client.get(
    `${endpoint}/${reportId}`,
    {
      ...populateData,
    },
    requestConfig()
  );

const getLatestUnread = (wineryId, period) =>
  client.get(
    endpoint,
    {
      "filters[Winery][id]": wineryId,
      "filters[Period]": period,
      "filters[Status]": "unread",
    },
    requestConfig()
  );

export default {
  get,
  update,
  getById,
  getLatestUnread,
};
